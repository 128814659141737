<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Peer Interaction Guidelines</h2>

      <p class="mb-3">Which of the following are guidelines for peer interaction?</p>

      <p v-for="option in optionsQ2shuffled" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.question2Values" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UCIrvineIntegrityAgreementQ2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question2Values: [],
      },
      optionsQ2: [
        {
          text: 'Listen actively and share ideas.',
          value: 'listenAnswer',
        },
        {
          text: 'Analyze evidence quality.',
          value: 'analyzeEvidenceAnswer',
        },
        {
          text: 'Share responsibilities.',
          value: 'shareAnswer',
        },
        {
          text: 'Discuss strengths and weaknesses of a plan.',
          value: 'planAnswer',
        },
        {
          text: 'Ask clarifying questions.',
          value: 'clarifyingAnswer',
        },
        {
          text: "Don't disagree with the poster presenter.",
          value: 'disagreeAnswer',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsQ2shuffled() {
      return seededShuffle(this.optionsQ2, this.seed);
    },
  },
};
</script>
